export const CreateTagAdmin = `mutation MyMutation($Name: String, $Slug: String, $Description: String) {
    createTagAdmin(input: {Name: $Name, Slug: $Slug, Description: $Description}) {
        Status
	    Message
    }
}`

export const UpdateTagAdmin = `mutation MyMutation($TagID: Int, $Name: String, $Slug: String, $Description: String) {
    updateTagAdmin(input: {TagID: $TagID, Name: $Name, Slug: $Slug, Description: $Description}) {
        Status
	    Message
    }
}`

export const ListTagAdmin = `query MyQuery($NumberItem: Int, $Page: Int, $FilterText: String) {
    getTagListAdmin(input: {NumberItem: $NumberItem, Page: $Page, FilterText: $FilterText}) {
        CountAll
        NumberItem
        Page
        FilterText
        Items {
            TagID
            Name
            Slug
            Description
            CreatedAt
            UpdatedAt
            Count
        }
    }
}`

export const DeleteTagAdmin = `mutation MyMutation($TagIDs: [Int]) {
    deleteTagAdmin(TagIDs: $TagIDs) {
        Status
	    Message
    }
}`

export const GetTagAdmin = `query MyQuery($TagID: Int!) {
    getTagAdmin(TagID: $TagID) {
        TagID
        Name
        Slug
        Description
        CreatedAt
        UpdatedAt
    }
}`